

































































































































































import {Component, Vue} from 'vue-property-decorator';
import {
  namespace,
} from 'vuex-class';
import {RoutingHelper} from '@/helper/RoutingHelper';
import Company from '@/models/Company';
import User from '@/models/User';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import UserRole from '@/models/user-attributes/UserRole';

const AuthStore = namespace('auth');
const CompanyStore = namespace('company');
const UserStore = namespace('user');

/**
 * This view provides a login for each user role.
 */
@Component({
  mixins: [validationMixin],
  validations: {
    loginData: {
      login: {required},
      password: {required},
    },
    forgotPasswordData: {
      login: {required},
    },
  },
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin) {

  public applicationName: string = process.env.VUE_APP_TITLE!;

  @AuthStore.Action('loginAction')
  private loginAction!: (value: { username: string, password: string }) => Promise<User>;
  @AuthStore.Action('resetPasswordAction')
  private resetPasswordAction!: (login: string) => Promise<void>;
  @UserStore.Action('loadUserRolesAction')
  private loadUserRoles!: (payload: { companyId: string }) => Promise<UserRole>;
  @CompanyStore.Mutation('activeCompany')
  private activeCompany!: (company: Company) => void;

  /**
   *  Login data model
   */
  public loginData: { login?: string, password?: string } = {
    login: undefined,
    password: undefined,
  };
  public loginFormWasSubmitted: boolean = false;

  /**
   *  Forgot password model
   */
  public forgotPasswordData: { login?: string } = {
    login: undefined,
  };

  /**
   * Support form variable
   */
  public loginForgotFormWasSubmitted: boolean = false;

  /**
   * Support form variable
   */
  public onForgotPasswordDialogFlag: boolean = false;

  /**
   *  Close handler for forgot password dialog handler
   */
  public async onForgotPasswordDialogClose() {
    this.onForgotPasswordDialogFlag = false;
    Vue.nextTick(() => {
      Object.keys(this.forgotPasswordData).forEach((key) => this.forgotPasswordData[key] = undefined);
      this.$v.forgotPasswordData!.$reset();
      this.loginForgotFormWasSubmitted = false;
    });
  }

  /**
   * Performs the reset password request
   */
  public async onForgotPassword() {
    this.$v.forgotPasswordData!.$touch();
    this.loginForgotFormWasSubmitted = true;

    try {
      if (!this.$v.forgotPasswordData!.$invalid && this.loginForgotFormWasSubmitted) {
        await this.resetPasswordAction(this.forgotPasswordData.login!);
        this.$notifySuccessSimplified('LOGIN.NOTIFICATIONS.RESET_PASSWORD.SUCCESS');
        this.onForgotPasswordDialogClose();
      }
    } catch (e) {
      if (this.$te(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.${e.status}.TEXT`)) {
        switch (e.status) {
          case 429:
            e.data.resendAllowedAt = this.$options.filters!.toDateTime(e.data.resendAllowedAt); // format date
            this.$notifyErrorSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.${e.status}`, e.data, 10000);
            break;
          case 410:
            this.$notifyErrorSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.410`);
            break;
          default:
            this.$notifyWarningSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.${e.status}`);
            break;
        }
      } else if (e.status === 410) {
        this.$notifyErrorSimplified(`LOGIN.NOTIFICATIONS.RESET_PASSWORD.410`);
      } else {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }

  /**
   * Performs the login
   */
  public async onLogin() {
    this.$v.loginData!.$touch();

    let loginUser: User;
    try {
      if (this.$v.loginData!.$invalid || this.loginFormWasSubmitted) {
        return;
      }
      this.loginFormWasSubmitted = true;
      loginUser = await this.loginAction({
        username: this.loginData.login!,
        password: this.loginData.password!,
      });
      this.loginFormWasSubmitted = false;
    } catch (e) {
      this.loginFormWasSubmitted = false;
      if (this.$te(`LOGIN.NOTIFICATIONS.${e.status}.TEXT`)) {
        this.$notifyError(
          this.$t(`LOGIN.NOTIFICATIONS.GENERAL_TITLE`).toString(),
          this.$t(`LOGIN.NOTIFICATIONS.${e.status}.TEXT`).toString(),
        );
      } else {
        this.$notifyErrorSimplified('LOGIN.NOTIFICATIONS.GENERAL_ERROR');
      }
      return;
    }

    // split login and routing to be able to differentiate between errors
    try {
      let optionalParams;

      // consider company id to route
      if (!this.$userRoleHandler.isSuperAdmin()) {
        this.activeCompany(loginUser.company as Company);
        optionalParams = {
          companyId: (loginUser.company as Company).id!,
        };
        await this.loadUserRoles({companyId: loginUser.companyId});
      }

      await this.$router.push({
        name: RoutingHelper.getDefaultRoute(loginUser),
        params: optionalParams,
      });
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
